<template>
    <custom-table
        :title="$t('pages.developer.videoManagement.downloader.title')"
        :subTitle="$t('pages.developer.videoManagement.downloader.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        changeUrlPage
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:progress="{ row: record }">
            <el-progress :text-inside="true" :stroke-width="20" :percentage="record.progress_percentage"/>
        </template>
        <template v-slot:createdAt="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <router-link :to="sprintf('/developer/video-management/downloader/%d/detail', [record.id])" target="_blank" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                    </span>
                </router-link>
                <el-tooltip :content="$t('common.download')" placement="top">
                    <a :href="sprintf('%s/%d/export',[endpoint.get('video_management_downloader', 'developer'), record.id])" target="_blank" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/files/fil021.svg" />
                        </span>
                    </a>
                </el-tooltip>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_downloader" ref="downloaderModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="downloaderForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('common.manager') }}</label>
                                <el-form-item prop="admin_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.admin_id" :placeholder="$t('common.chooseSelect')" class="w-100">
                                        <el-option v-for="(admin, adminIndex) in admins" :key="adminIndex" :value="admin.id" :label="admin.full_name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.videoManagement.downloader.cols.videoGroup') }}</label>
                                <el-form-item prop="video_group_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.video_group_id" :placeholder="$t('common.chooseSelect')" class="w-100">
                                        <el-option v-for="(videoGroup, videoGroupIndex) in videoGroups" :key="videoGroupIndex" :value="videoGroup.id" :label="videoGroup.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.videoManagement.downloader.cols.fileSchema') }}</label>
                                <el-form-item prop="file_schema_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.file_schema_id" :placeholder="$t('common.chooseSelect')" class="w-100">
                                        <el-option v-for="(schema, schemaIndex) in fileSchemas" :key="schemaIndex" :value="(schemaIndex + 1)" :label="schema"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.videoManagement.downloader.cols.file') }}</label>
                                <el-form-item prop="file">
                                    <el-upload :file-list="form.fileList" accept="application/json" class="col-lg-8 fv-row" multiple :on-change="handleChangeFile" :on-remove="handleChangeFile" :auto-upload="false" :limit="1">
                                        <el-button type="primary">{{ $t('common.chooseFile') }}</el-button>
                                    </el-upload>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.developer.videoManagement.downloader.cols.corporation"),
                    key: "admin.full_name"
                },
                {
                    name: this.$t("pages.developer.videoManagement.downloader.cols.videoGroup"),
                    key: "video_group.name"
                },
                {
                    name: this.$t("common.total"),
                    key: "items_count"
                },
                {
                    name: this.$t("common.inProcess"),
                    key: "downloading_items_count"
                },
                {
                    name: this.$t("common.pending"),
                    key: "pending_items_count"
                },
                {
                    name: this.$t("common.completed"),
                    key: "downloaded_items_count"
                },
                {
                    name: this.$t("common.progress"),
                    scopedSlots: {customRender: "progress"}
                },
                {
                    name: this.$t("common.createdAt"),
                    scopedSlots: {customRender: "createdAt"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                fileList: [],
                data: {}
            },
            fileSchemas: ['VIMEO']
        }
    },
    computed: {
        table() {
            return this.$store.state.developer.videoManagement.downloader.table;
        },
        videoGroups() {
            return this.$store.state.video.group.table.data;
        },
        admins() {
            return this.$store.state.developer.customer.table.data;
        },
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.developerManagement.videoManagement.downloader"), [this.$t("menu.developerManagement.title"), this.$t("menu.developerManagement.videoManagement.title")]);
        this.$store.dispatch('developer/videoManagement/downloader/get', {
            page: {},
            filterData: this.$root.getFilterWithUrl()
        });
        this.$store.dispatch('video/group/get', {
            page: { pageSize: 9999 }
        });
        this.$store.dispatch('developer/customer/get', {
            page: { pageSize: 9999 }
        });
    },
    methods:{
        newRecord(){
            this.form.updateStatus = false;
            this.form.data = {};
            this.form.fileList = [];
            this.form.title = this.$t("pages.developer.videoManagement.downloader.new");
            this.showModal(this.$refs.downloaderModal);
        },
        onSubmit(){
            this.$refs.downloaderForm.validate((valid) => {
                if(valid) {
                    if(!(this.form.fileList.length > 0)){
                        this.$notify({
                            type: 'warning',
                            title: this.$t("messages.warning"),
                            message: this.$t("pages.developer.videoManagement.downloader.warnings.requiredFile"),
                        })
                        return false;
                    }

                    this.form.loading = true;
                    const formData = new FormData();

                    if(this.form.fileList.length == 1) {
                        formData.append("file", this.form.fileList[0].raw);
                    }

                    for (let key in this.form.data){
                        formData.append(key, (typeof(this.form.data[key]) == 'boolean' ? (Number(this.form.data[key])) : this.form.data[key]));
                    }

                    this.axios.post(this.endpoint.get('video_management_downloader', 'developer'), formData).then(response => {
                        this.onResponse(response.data, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.downloaderModal);
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRecord();
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("developer/videoManagement/downloader/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("developer/videoManagement/downloader/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleChangeFile(file, fileList){
            this.form.fileList = fileList;
        },
    }
}
</script>

<style>
</style>